.text-light-blue {
    color: var(--light-blue);
}

.text-dark-blue-2 {
    color: var(--dark-blue-2);
}

.text-dark-blue-3 {
    color: var(--dark-blue-3);
}

.text-green-2 {
    color: var(--green-2);
}

.bg-gray-dark {
    background: var(--dark-grey-3) !important;
}

.app-slider .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}

.app-slider .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #ffffff;
    background: #000000;
}

.app-slider .dot:focus {
    outline: none;
}

.app-slider .dot.active {
    background: #3364fa;
}

.app-colored-text {
    font-family: Google Sans;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: left;
    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-sub-title-text {
    font-family: Google Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #718096;
}

.app-description-text {
    font-family: Google Sans;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
    letter-spacing: 0.04em;
    text-align: left;
    margin: 0;
    color: var(--black);
}

.app-green-header {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0.06em;
    text-align: left;
    color: var(--green-2);
    text-transform: uppercase;
}

.flex-basis-70 {
    flex-basis: 70%;
}

.app-clients-block {
    position: relative;

    top: -120px;
}

.app-clients-block .heading {
    font-family: Google Sans;
    font-size: 30px;
    font-style: normal;
    line-height: 60px;
    letter-spacing: 0em;
    color: #283560;
}

.app-clients-block .container {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 15px 81px 0px #0000000f;
}

.app-clients-block .images-block {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.app-clients-block .images-block img {
    width: 80px;
    height: 50px;
    margin: 1rem 2rem;
    object-fit: contain;
}

.app-heading-styles h2 {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 0;
}

.app-card {
    background: var(--white);
    padding: 2rem;
    box-shadow: 0px 4px 41px 0px #00000012;
}

.app-contact-form .get-started {
    background: #2c3b6b;
    color: #ffffff;
    padding: 3rem 0;
    margin-top: 3rem;
}

.app-contact-form .get-started .title {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #ffffff;
}

.app-contact-form .get-started input {
    border-radius: 24px;
}

.app-contact-form .get-started .sub-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
}

.app-contact-form .get-started form {
    font-size: 17px;
}

.app-contact-form .get-started .check-tips {
    padding-right: 2.1em;
}

.app-contact-form .get-started .first-block {
    flex: 55%;
}

.app-contact-form .get-started .second-block {
    flex: 40%;
}

.app-slides {
    background: #f0f3fd;
    padding: 5rem 0;
}

.app-slides .slides-block {
    margin-top: 4rem;
}

.app-slides .sub-title {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.06em;
    text-align: center;

    color: #00be99;
}

.app-slides .title {
    font-family: Google Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #283560;
    text-align: center;
}

.app-slides .title-colored {
    background: linear-gradient(90.11deg, #3364fa 0.09%, #25cf41 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-slides .slides-block .images {
    max-width: 80%;
    overflow: hidden;
}

.app-slides .slides-block button {
    outline: none;
    outline-color: #fff;
    border: none;
}

.app-slides .slides-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-cta-content-with-image,
.app-cta-content-with-image > .container {
    display: flex;
    justify-content: space-between;
    margin: auto auto;
}

.app-cta-content-with-image:nth-of-type(1) {
    margin-bottom: 0;
}

.app-cta-content-with-image .content-block {
    flex-basis: 50%;
}

.app-cta-content-with-image .image-block {
    flex-basis: 50%;
}

.app-cta-content-with-image .image-block img {
    max-width: 500px;
    width: 90%;
    object-fit: contain;
}

.app-cta-content-with-image .content-block .title {
    font-family: Google Sans;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--dark-grey);
}

.app-cta-content-with-image .content-block .description {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    text-align: left;
    color: var(--light-grey);
}

.team-inbox-content .content-block .description {
    font-size: 17px;
}

.app-accordion {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.app-accordion .app-card {
    background: var(--white);
    padding: 6px 20px;
    border-radius: 15px;
    margin-top: 20px;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    border: none;
    width: 100%;
    max-width: 600px;
}

.app-accordion .content {
    flex-basis: 55%;
    align-self: start;
}

.app-accordion .image-block {
    flex-basis: 40%;
}

.app-accordion .image-block img {
    max-width: 600px;
    width: 100%;
    object-fit: contain;
}

.app-accordion .app-card-closed {
    background: transparent;
    box-shadow: none;
    border: 1px solid #d3d7de;
}

.app-accordion .description {
    min-height: fit-content;
    transition: 0.3s all ease-in-out;
}

.app-accordion .app-card-closed .description {
    max-height: 0px;
    overflow: hidden;
    margin-top: 0;
}

.app-accordion .title {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: var(--black);
    margin-top: 9px;
}

.app-accordion .description {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-top: 0.15rem;
    color: var(--light-grey-2);
}

.colored-text-green {
    background: linear-gradient(90.11deg, #378f46 0.09%, #52925c 0.1%, #60ba6e 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.colored-text-blue {
    background: linear-gradient(90.11deg, #55618d 0.09%, #5b71bd 99.96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.colored-text-red {
    background: linear-gradient(93.44deg, #a25f5f 6.63%, #d06262 98.87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.client-testimonials {
    padding: 5rem 0;
    background: var(--dark-blue-2);
    color: var(--white);
    margin-top: 5rem;
}

.client-testimonials .top-text {
    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.06em;
    text-align: center;
    color: #60ffe0;
}

.client-testimonials .title {
    font-family: Google Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-bottom: 2rem;
}

.client-testimonials .app-card {
    color: var(--black);
    border-radius: 22px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;

    padding: 3rem 4rem;

    max-width: 80%;
    margin: auto;

    position: relative;
    min-height: 350px;
}

.client-testimonials .app-card .card-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.02em;
    color: var(--dark-blue-2);
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: center;
}

.client-testimonials .app-card .card-description {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.03em;
    text-align: center;
    color: var(--light-grey-3);
    margin-bottom: 1.5rem;
}

.client-testimonials .client-details {
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-testimonials .client-details .client-image {
    width: 90px;
    height: 70px;
    object-fit: contain;
    margin-top: auto;
}

.client-testimonials .client-details .client-name {
    font-family: Google Sans;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-align: left;
    color: var(--black);
    margin-bottom: 0.3rem;
    margin-top: auto;
}

.client-testimonials .client-details .client-description {
    font-family: Google Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-align: left;
    color: var(--light-grey);
}

.client-testimonials .slide-count {
    position: absolute;
    bottom: 13px;
    right: 31px;

    font-family: Google Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: right;

    color: var(--dark-grey-2);
}

.client-testimonials .left-arrow {
    position: absolute;
    top: 230px;
    left: 50px;
    outline: none;
}

.client-testimonials .right-arrow {
    position: absolute;
    right: 50px;
    top: 230px;
    outline: none;
}

.client-testimonials .upperQuote {
    position: absolute;
    top: 100px;
    left: 25px;
}

.client-testimonials .bottomQuote {
    position: absolute;
    right: 25px;
    bottom: 90px;
}

.app-client-logos .title {
    font-family: Google Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #000;
}

.app-client-logos .logo-block-wrapper {
    display: flex;
    justify-content: space-between;
}

.submenu-item-integration a:hover {
    background: #e2e8f0;
    border-radius: 6px;
}

.app-client-logos .logo-wrapper {
    box-shadow: 1px 1px 2px 0px #aaaacc80 inset;
    background: #f5f5fa;

    border-radius: 40px;

    padding: 1rem 1.5rem;
}

.arrow-right:hover,
.arrow-right:focus,
.arrow-left:hover,
.arrow-left:focus,
.arrow-right img,
.arrow-left img {
    outline: none;
    border: none;
}

.videoopacity {
    opacity: 1 !important;
}

.app-client-logos .logo {
    width: 100%;
    height: 40px;
    object-fit: contain;
}

.personalize-card {
    width: 180px;
    height: 170px;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    background: white;
    border: 1px solid #d4d4d6;
    border-radius: 27px;
}

.personalize-card img {
    height: 40px;
    width: 40px;
    margin-bottom: 0.5rem;
    object-fit: contain;
}

.personalize-card .text {
    font-family: Google Sans;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    line-height: 24px;
    letter-spacing: 0.04em;

    text-align: center;
    color: #000000;
}

.broadcast-cta {
    background: url("https://assets.gallabox.com/gb-home/whatsapp-broadcast/broadcast_background.png");
    padding: 2rem 0;
    margin-top: 7rem;
}

.broadcast-cta .title {
    font-family: Google Sans;
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 43px !important;
    letter-spacing: 0.02em;
    text-align: left;
    color: var(--white);
}

.broadcast-cta .button-cta {
    background: var(--white);
    color: var(--light-blue);
    min-width: 120px;
}

.image-content-block .heading {
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0.02em;
}

.image-content-block .description {
    font-family: Google Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.04em;
    color: var(--light-grey);
}

.whatsapp-contact input {
    /* background-color: #1F2937; */
    color: black;
    /* font-size: 10px !important; */
}

.image-content-block .reverse {
    background: #ecf0f3;
}

.image-content-block .reverse .flex {
    flex-flow: row-reverse;
}

.image-content-block .reverse .description {
    max-width: 80%;
}

.image-content-wrapper {
    padding: 6rem 0;
}

.client-testimonial-card {
    max-width: 270px;
    background: #ffffff;
}

.client-testimonial-masonary {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 700px;
}

.client-testimonials-card-wrapper {
    padding: 5rem 0;
    background: #ecf0f3;
}

.client-testimonials-card-wrapper .top-text {
    font-family: Google Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.06em;
    text-align: center;
    color: #3364fa;
}

.client-testimonials-card-wrapper .title {
    font-size: 34px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #182242;
}

.client-testimonial-card {
    padding: 1rem;
    border-radius: 0px 26px 26px 26px;
    margin-right: 1rem;
    margin-bottom: 2rem;
    box-shadow: 0px 4px 18px 0px #aeaec033;
}

.client-testimonial-card:nth-of-type(4n) {
    margin-right: 0;
}

.client-testimonial-card .icon {
    max-width: 80px;
    object-fit: contain;
}

.client-testimonial-card .description {
    font-family: Google Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #5d6b7e;
}

.client-testimonial-card .name {
    font-family: Google Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;

    color: var(--black);
}

.image-content-block .image-content-wrapper .heading {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: 0.02em;
    color: #000000;
}

.image-content-block .image-content-wrapper .description {
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.04em;
    color: #5d6b7e;
}

.home-banner {
    position: absolute;
    z-index: -1;
    height: 80%;
}

.home-white {
    position: absolute;
    background-color: white;
    height: 93%;
    width: 88%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 35%;
    top: -95px;
    /* z-index: 0; */
    filter: blur(77px);
}

.home-brown {
    /* background-color: #fff3d1; */
    background-color: #0083ff30;
    height: 483px;
    width: 627px;
    top: -303px;
    left: -373px;
    /* z-index: -1; */
    transform: rotate(45deg);
    filter: blur(53px);
}

.home-blue {
    /* background-color: #dee6ff; */
    background-color: #0083ff30;
    height: 454px;
    width: 568px;
    top: -201px;
    right: -242px;
    /* z-index: -1; */
    transform: rotate(45deg);
    filter: blur(53px);
}

.text-black {
    color: black !important;
}

@media screen and (max-width: 426px) {
    .desktop-footer {
        display: none;
    }

    .container {
        padding: 0 1.5rem;
    }

    .app-colored-text {
        font-size: 40px !important;
        width: 100%;
        line-height: 65px;
    }

    .app-sub-title-text {
        width: 100%;
    }

    .app-description-text {
        font-size: 42px !important;
        line-height: 60px;
        margin-bottom: 1rem;
    }

    .cta-buttons {
        margin: 3rem 0;
    }

    .app-clients-block .images-block {
        flex-wrap: wrap;
    }

    .app-heading-styles {
        flex-flow: column;
    }

    .app-contact-form .get-started .title-block {
        margin-bottom: 0;
    }

    .app-contact-form .get-started .container > .flex {
        flex-flow: column;
    }

    .app-contact-form .get-started .title {
        text-align: center;
        margin-bottom: 0;
    }

    .app-contact-form .get-started input {
        width: 100%;
        margin: 1rem 0;
    }

    .app-contact-form .get-started .check-tips {
        padding-right: 0;
        justify-content: space-around;
        flex-flow: column;
        margin-top: 2rem;
        align-items: start;
    }

    .app-contact-form .justify-end {
        justify-content: center;
    }

    .app-contact-form button {
        padding: 1rem;
        width: 100%;
    }

    .app-accordion {
        flex-flow: column;
    }

    .app-accordion .image-block {
        margin-top: 4rem;
    }

    .app-accordion .description {
        max-height: 100px;
    }

    .app-cta-content-with-image {
        flex-flow: column;
        margin: 4rem 0;
    }

    .app-cta-content-with-image .content-block .title {
        margin-top: 0;
    }

    .app-cta-content-with-image .image-block img {
        margin: 3rem auto;
    }

    .client-testimonials .upperQuote {
        top: 125px;
    }

    .client-testimonials .left-arrow {
        top: 350px;
        left: -10px;
    }

    .client-testimonials .right-arrow {
        top: 350px;
        right: -10px;
    }

    .client-testimonials .app-card {
        padding: 1.5rem;
    }

    .client-testimonials .app-card .card-title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.02em;
    }

    .client-testimonials .app-card .card-description {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.03em;
    }

    .client-testimonials .slide-count {
        display: none;
    }

    .client-testimonials .client-details .client-name {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.02em;
    }

    .client-testimonials .client-details .client-description {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.02em;
    }

    .client-testimonials .client-details .client-image {
        width: 50px;
        height: 70px;
        object-fit: contain;
        margin-top: auto;
    }

    .personalize-card {
        width: 150px;
        margin-bottom: 3rem;
    }

    .app-client-logos .logo-block-wrapper {
        flex-flow: column;
    }

    .app-client-logos .logo-wrapper {
        margin: 1rem 0;
    }

    .image-content-wrapper .w-1\/2,
    .image-content-wrapper .w-2\/3,
    .image-content-block .reverse .description {
        width: 100%;
    }

    .client-testimonial-masonary {
        max-height: unset;
        max-width: 85%;
        margin: auto;
    }

    .image-content-wrapper .container > .flex {
        flex-flow: column;
    }

    .image-content-wrapper .text-block,
    .image-content-wrapper.reverse .text-block {
        margin-left: 0;
        margin-right: 0;
    }

    @media (max-width: 750px) {
        .image-content-wrapper .text-block h4,
        .image-content-wrapper.reverse .text-block h4 {
            margin-top: 15px !important;
        }
    }

    .client-testimonial-card {
        max-width: 250px;
        margin: auto;
    }

    .client-testimonial-masonary {
        display: none;
    }

    .home-banner {
        position: absolute;
        z-index: -1;
        height: 80%;
    }

    .home-white {
        position: absolute !important;
        background-color: white !important;
        background-color: white !important;
        height: 60% !important;
        width: 74% !important;
        left: 50% !important;
        transform: translateX(-59%) rotate(222deg) !important;
        border-radius: 35% !important;
        top: 116px !important;
        /* z-index: 0 !important; */
        filter: blur(77px) !important;
    }

    .home-brown {
        /* background-color: #fff3d1 !important; */
        background-color: #0083ff30;
        height: 483px !important;
        width: 627px !important;
        top: -275px !important;
        left: -423px !important;
        /* z-index: -1 !important; */
        filter: blur(53px) !important;
    }

    .home-blue {
        /* background-color: #dee6ff !important; */
        background-color: #0083ff30;
        height: 483px !important;
        width: 627px !important;
        top: -275px !important;
        right: -428px !important;
        /* z-index: -1 !important; */
        filter: blur(53px) !important;
    }
}

.start-trial-btn {
    background-color: #3364fa;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    padding: 0.5em 1em;
    min-width: fit-content;
}

.start-trial-btn-white {
    background-color: white;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    color: #0083ff;
    padding: 0.5em 1em;
    min-width: fit-content;
}

/* ----- home update ----  */

.image-content-block .title {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 47px;
    color: #1a202c;
}

.image-content-block .list {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #344054;
}

.image-content-block .bgcolor-default {
    background: #fff;
}

.card-with-details {
    background-image: url("https://assets.gallabox.com/gb-home/content-with-image/bg-testimonial-new.svg");
    background-size: cover;
}

.new-testimonial .title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 54px;
    letter-spacing: 0.02em;
    color: #ffffff;
}

.new-testimonial .btn {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #0083ff;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 0.5em 1.5em;
    margin: -12px 0px;
}

.new-testimonial .content {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #2d3748;
}

.new-testimonial .name {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.92);
}

.new-testimonial .role {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

/* .app-slider .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}

.app-slider .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
    border: 1px solid #ffffff;
    background: #000000;
}

.app-slider .dot:focus {
    outline: none;
}

.app-slider .dot.active {
    background: #3364FA;
} */
.head-above-text {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #718096;
}

.card-carousal-card {
    height: 320px;
    width: 270px;
}

@media (max-width: 720px) {
    .head-above-text {
        font-size: 12px;
    }
}

.testimoinal-card {
    width: 549px;
    height: 331px;
    background: #ffffff;
    box-shadow: 0px 19px 92px rgba(99, 103, 117, 0.13);
    position: absolute;
    left: 52%;
    top: 56px;
    padding-bottom: 1em;
}

@media (max-width: 720px) {
    .testimoinal-card {
        width: 549px;
        height: 360px;
        background: #ffffff;
        box-shadow: 0px 19px 92px rgba(99, 103, 117, 0.13);
        position: absolute;
        left: 52%;
        top: 56px;
        padding-bottom: 1em;
    }
}

/* .app-home header{
    background-image: url("/home-page-pattern.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
} */

.home-banner {
    position: absolute;
    z-index: -1;
    height: 80%;
}

.home-white {
    position: absolute;
    background-color: white;
    height: 93%;
    width: 88%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 35%;
    top: -95px;
    /* z-index: 0; */
    filter: blur(77px);
}

.home-brown {
    /* background-color: #fff3d1; */
    background-color: #0083ff30;
    height: 483px;
    width: 627px;
    top: -303px;
    left: -373px;
    /* z-index: -1; */
    transform: rotate(45deg);
    filter: blur(53px);
}

.home-blue {
    /* background-color: #dee6ff; */
    background-color: #0083ff30;
    height: 454px;
    width: 568px;
    top: -201px;
    right: -242px;
    /* z-index: -1; */
    transform: rotate(45deg);
    filter: blur(53px);
}

.app-home header::after {
    background-color: red;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.app-home .head-title {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 80px;
    letter-spacing: 0.02em;
    position: relative;
}

.app-home .home-hero-image-container {
    box-sizing: content-box;
    padding-inline: 2em;
    max-width: 1200px;
    max-height: 800px;
    margin-inline: auto;
    margin-block: 2.5em;
}

.app-home .head-title .conversation-design {
    position: relative;
    color: #0083ff;
}

/* .app-home .head-title .conversation-design::after{
    content: url("/conversation-bg-home-page.svg");
    z-index: -20;
    position: absolute;
    right:-15px;
    top:1px;
}
.app-home .head-title::after{
    content: url("/header-bg-arrow.svg");
    position: absolute;
    bottom:-10px;
} */
.head-title .customer-design {
    position: relative;
}

/* .head-title .customer-design::before{
      content: url("/smile-home-header.svg");
      position: absolute;
     left: 190px;
     top:-11px;
} */
/* .head-title .customer-design::after{
    content: url("/yellow-drops.svg");
    position: absolute;
    top:-42px;
} */
.app-home .sub-head {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.02em;
}

.app-home .head-points,
.team-inbox-header .head-points,
.tick-points {
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    /* color: #a0aec0; */
    min-width: fit-content;
}

.tick-points {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #4a5568;
}

.app-home .home-button {
    z-index: 1;
}

.mask {
    position: relative;
    margin-top: 6px;
    top: 102px;
    /* display: inline-block; */
}

.mask span {
    display: block;
    box-sizing: border-box;
    position: absolute;
    /* top: 103px; */

    background-size: 100% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-repeat: no-repeat;
}

.mask span[data-show] {
    transform: translateY(-100%);
    transition: 0.5s transform ease-in-out;
}

.mask span[data-up] {
    transform: translateY(-215%);
    transition: 0.5s transform ease-in-out;
}

.mask span:nth-child(1) {
    background-image: linear-gradient(45deg, #0083ff 50%, #0083ff);
}

.mask span:nth-child(2) {
    background-image: linear-gradient(45deg, #0083ff 50%, #0083ff);
}

.mask span:nth-child(3) {
    background-image: linear-gradient(45deg, #0083ff 50%, #0083ff);
}

.mask span:nth-child(4) {
    background-image: linear-gradient(45deg, #0083ff 50%, #0083ff);
}

.mask span:nth-child(5) {
    background-image: linear-gradient(45deg, #0083ff 50%, #0083ff);
}

.review_rating:hover {
    border-radius: 8px;
    box-shadow: 0px 8px 8px -4px rgba(0, 131, 255, 0.08), 0px 20px 24px -4px rgba(0, 131, 255, 0.16);
    transform: scale(1.1);
}

.above-navbar {
    background-color: #09182c;
    color: #e8e8e8;
}

/* .whole-body-top-padding{
    padding-top: 3rem !important;
} */
/* ---- reset ---- */
.github {
    bottom: 10px;
    right: 10px;
    position: fixed;
    border-radius: 10px;
    background: #fff;
    padding: 0 12px 6px 12px;
    border: 1px solid #000;
}

.github a:hover,
.github a:link,
.github a:visited,
.github a:active {
    color: #000;
    text-decoration: none;
}

.github img {
    height: 30px;
}

.github #gh-project {
    font-size: 20px;
    padding-left: 5px;
    font-weight: bold;
    vertical-align: bottom;
}

.blue-testimonial-card {
    max-width: 864px;
    height: 443px;
}

@media (max-width: 720px) {
    .blue-testimonial-card {
        max-width: 864px;
        height: 373px;
    }

    .whole-body-top-padding {
        padding-top: 0 !important;
    }

    .above-navbar {
        font-size: 13px;
    }

    .new-testimonial {
        height: 850px;
    }

    .testimoinal-card {
        width: 100vw;
        position: absolute;
        bottom: 0px;
        top: 375px;
        left: 0px;
        right: 0;
        padding-inline: 1em;
    }

    .app-home .head-title {
        font-family: "Google Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.02em;
        position: relative;
        width: 88%;
    }

    .image-content-wrapper {
        padding: 2.5rem 0px;
    }

    .app-home .sub-head {
        font-family: Google Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: center;
        margin: auto 13px;
    }

    .image-content-block .title {
        font-family: "Raleway";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 38px;
        color: #344054;
    }

    .image-content-block .list {
        font-family: "Google Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        letter-spacing: 0.02em;
        color: #4a5568;
    }

    .app-home .home-button {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-flow: column;
    }

    .app-home .home-hero-image-container {
        padding-inline: 0em;
    }

    .app-home .head-title .conversation-design::after {
        content: "";
        /* z-index: -20;
        position: absolute;
        right:-15px; */
    }

    .app-home .head-title::after {
        content: "";
        /* position: absolute; */
    }

    .app-home .msg-gif {
        margin-top: 40px;
        margin-left: 10px;
    }

    .app-home .phone-pic {
        top: 130px;
        margin: auto auto;
        margin-bottom: 160px;
        width: 276px;
    }

    .app-home .mobile-logo-view {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 27px;
        row-gap: 9px;
    }

    .app-home .mobile-logo-view div {
        width: 100%;
        max-width: 120px;
        justify-content: center;
    }

    .app-home .app-client-logos .title h2 {
        font-family: "Google Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        /* identical to box height */

        text-align: center;
        letter-spacing: 0.02em;
    }

    .app-home .logo-mobile {
        margin-top: 25px !important;
    }

    .mob-nav-item {
        background-color: #edf2f7;
        padding: 10px;
        display: flex;
        gap: 16px;
        align-items: flex-start;
    }

    .mob-nav-item img {
        height: 50px;
        width: 50px;
        margin-top: -4px;
    }

    .mob-nav-item .title {
        font-family: "Google Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.03em;

        /* gray/900 */

        color: #171923;
    }

    .tab-bottom-custom {
        padding: auto 5px;
    }

    .padding-custom {
        padding-top: 19px;
        padding-bottom: 9px;
    }

    .paading-custom a:hover {
        text-decoration: none;
    }

    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .hide-scrollbar {
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
    }

    .mob-nav-item .description {
        font-family: "Google Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        /* or 150% */

        letter-spacing: 0.03em;

        /* gray/500 */

        color: #718096;
        padding-right: 77px;
    }

    .expand-toggle-icon svg {
        fill: #171923;
        stroke: #171923;
        stroke-width: 1.2px;
    }

    .head-title .customer-design::before {
        content: "";
        /* position: absolute;
         left: 190px;
         top:-3px; */
    }

    .head-title .customer-design::after {
        content: "";
    }

    .home-banner {
        position: absolute;
        z-index: -1;
        height: 80%;
    }

    .home-banner img {
        scale: 329%;
    }

    .home-white {
        position: absolute !important;
        background-color: white !important;
        background-color: white !important;
        height: 60% !important;
        width: 74% !important;
        left: 50% !important;
        transform: translateX(-59%) rotate(222deg) !important;
        border-radius: 35% !important;
        top: 116px !important;
        /* z-index: 0 !important; */
        filter: blur(77px) !important;
    }

    .home-brown {
        /* background-color: #fff3d1 !important; */
        background-color: #0083ff30;
        height: 483px !important;
        width: 627px !important;
        top: -275px !important;
        left: -423px !important;
        /* z-index: -1 !important; */
        filter: blur(53px) !important;
    }

    .home-blue {
        /* background-color: #dee6ff !important; */
        background-color: #0083ff30;
        height: 483px !important;
        width: 627px !important;
        top: -275px !important;
        right: -428px !important;
        /* z-index: -1 !important; */
        filter: blur(53px) !important;
    }
}

.main-button:hover {
    background: #0069cc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.17);
}

/* --home - header - animation -- */

.home-head-convo {
    margin-left: -27%;
}

.white-top {
    background-color: white;
}

.head-text-top-span {
    margin-left: 1.5%;
    font-family: "Google Sans";
    font-style: normal;
    font-weight: 800;
    font-size: 72px;
    line-height: 88px;
    overflow: hidden;
    height: 98px;
    bottom: 2px;
    width: 100%;
}

/* .head-text-anim{
    background-color: red;
    position: relative;
  }
  .head-text-anim::after{
    content: "";
    position: absolute;
    background-color: yellow;
    width: 100%;
    height: 50px;
    top: 0px;
    filter: blur(20px);

  } */

.header-leaf {
    display: inline;
    height: auto;
    height: auto;
    top: 0px;
    margin-bottom: 51px;
}

.sm-white-blk {
    position: absolute;
    background-color: rgb(255, 255, 255);
    height: 35px;
    width: 35px;
    top: 23px;
    border-radius: 11px;
    transform-origin: bottom left;
    animation: rotatediv;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes rotatediv {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(55deg);
    }

    100% {
        transform: rotate(90deg);
    }
}

.pricing-card-wrap {
    width: 340px;
    position: relative;
}

.scale-wrap {
    margin-top: -2.5rem;
}

.most-popular {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    text-align: center;
    color: white;
}

.comparisons-header {
    background-color: #ffffff;
    background-image: url(/comparison-page/header-banner.svg);
    margin-top: 66px;
    background-repeat: no-repeat;
}

.qr-benefits {
    width: 100%;
    max-width: 1031px;
    padding-right: 100px;
}

.qr-benefits .qr-icon {
    margin-left: -15px;
    padding-right: 8px;
}

.create-qr-form input {
    border: 2px solid #dfdfdf;
    border-radius: 6px;
}

.create-qr-form .fixed-wd {
    width: 482px;
}

.pricing-table > div {
    background-color: white;
    min-height: 72px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
}

.pricing-table-title {
    grid-column: 1/5;
}

.no-gap-pricing {
    margin-left: -1px;
    margin-right: -1px;
}

.pricing-table {
    display: grid;
    grid-template-columns: 291px 1fr 1fr 1fr;
    gap: 0;
}

.pricing-table > * {
    border-bottom: 1px solid #e1e1ed;
}

.pricing-table-outer-sticky {
    position: sticky;
    top: 16px;
    border-radius: 6px 6px 0px 0px;
    z-index: 9999;
}

.pricing-table-outer > div {
    background-color: white;
    min-height: 44px;
    padding: 10px 10px;
}

.pricing-table-outer-sticky .try-btn {
    font-weight: 500;
    font-size: 16px;
    border: 1px solid #0083ff;
    color: #0083ff;
    border-radius: 6px;
    padding: 6px 12px;
    outline: none;
}

.sticky-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.pricing-table-title {
    margin-bottom: -1px;
}

.comp-pros-table-grid {
    display: grid;
    grid-template-columns: 291px 355px 355px;
    gap: 2px;
    background-color: #d0e5ff;
    width: 1014px;
}

.comp-pros-table {
    display: inline-block;
}

.grid-item-comp {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(237, 245, 255);
    font-weight: 500;
    font-size: 18px;
    line-height: 47px;
    color: black;
    /* width: 219px; */
    height: 119px;
    padding: 0 40px;
}

.pros-cons .pros {
    border: 5px solid rgba(79, 133, 70, 0.2);
    color: rgba(34, 84, 61, 1);
    background: #efffed;
    width: 100%;
    max-width: 405px;
    padding: 26px 40px;
    border-radius: 12px;
}

.pros-cons .cons {
    border: 5px solid rgba(148, 5, 52, 0.2);
    color: rgba(130, 39, 39, 1);
    background: #ffeded;
}

.pricing-table-section {
    /* display: none; */
    display: none;
    animation: showTable 200ms ease-in-out forwards;
}

#more-info:checked ~ label .expand-toggle-icon {
    transform: rotate(-180deg);
}

#more-info:checked ~ .pricing-table-section {
    display: block !important;
}

.pricing-more-info svg {
    stroke: #0083ff;
    stroke-width: 1.2px;
}

@keyframes showTable {
    0% {
        opacity: 0%;
    }

    /* 50% {
        height: 10%;
    } */

    100% {
        opacity: 100%;
    }
}

.desktop-footer {
    padding-top: 160px;
    background-image: url("/footer-bg-2.png");
    background-position-x: center;
    background-repeat: no-repeat;
}

.footer-border {
    border-top: 1px solid #e6e6e6;
}

.green-bg-text {
    font-weight: 800;
    position: relative;
    z-index: 0;
    display: inline-block;
}

.green-bg-text::before {
    /* background-color: green; */
    content: "";
    top: 16px;
    left: 0px;
    width: 100%;
    height: 36%;
    background: #d0fca5;
    position: absolute;
    z-index: -1;
}

.wa-advan-box {
    background: linear-gradient(293.98deg, #e0f4ff 0%, #eff7ff 98.13%);
    border-radius: 11.8224px;
}

.visa-header-banner {
    background-color: black;
    background-image: url(https://assets.gallabox.com/gb-home/visa-offer/visa-header.png);
    background-size: cover;
}

.visa-header-banner h1 {
    font-size: 45px;
}

.two-card-testimonial {
    height: 333px !important;
}

.visa-page-form {
    /* display: flex; */
    flex-flow: column;
    /* width: 100%; */
    max-width: 482px;
    /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1); */
    /* border-radius: 16px; */
    /* padding: 40px 60px; */
    /* gap: 13px; */
}

.visa-page-form label {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2d3748;
}

.visa-page-form input {
    background: #ffffff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    padding: 10px 14px;
}

.signup-btn-visa {
    padding: 10px 36px;
    /* width: 346px; */
    background: #0083ff;
    border-radius: 6px;
    color: white;
}

.w-510 {
    width: 100%;
    max-width: 510px;
}

.key-features-visa {
    width: 100%;
    max-width: 540px;
}

.case-study-card-new {
    margin-bottom: -18px;
    margin-top: 18px;
    line-height: 28px;
}

.propleaf-video-cont {
    width: 767px;
    height: 431px;
    margin: 31px auto;
}

.nxt-wave-impact-para {
    color: #4a5568;
}

.head-blue-txt {
    font-size: 48px;
    font-weight: 700;
    color: #0083ff;
    display: block;
}

/* .industries-header-head > div{
    width: 54%;
} */
.industries-header-head button {
    border-radius: 6px;
    padding: 9px 35px !important;
    font-weight: 500 !important;
    font-size: 18.0131px !important;
}

.industries-page-logo .images-block {
    display: flex;
    justify-content: center;
    gap: 60px;
    flex-wrap: wrap;
}

.industries-h2 {
    color: #283560;
    font-size: 36px;
    font-weight: 700;
}

.yellow-cta {
    background-color: #ffed82;
    width: 100%;
}

.yellow-cta input {
    background: #ecc94b;
    border-radius: 6px;
    padding: 13px 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: #744210;
    width: 100%;
    max-width: 249px;
}

.yellow-cta input::placeholder {
    color: #744210;
}

.gallabox-badges {
    background: linear-gradient(0deg, #0083ff -18.7%, #08335b 62.43%);
    padding: 52px 0px;
}

.gallabox-badges h2 {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: white !important;
    margin-bottom: 42px !important;
    margin-top: 0px;
}

.gallabox-badges .big-badges img {
    height: 248px;
    width: 100%;
    max-width: 206px;
}

.gallabox-badges .small-badges {
    width: 100%;
    max-width: 698px;
    column-gap: 45px;
    row-gap: 24px;
}

.industries-bot {
    background-color: #2c3b6b;
}

.industries-bot h2 {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: white !important;
    text-align: center;
}

.multi-ling div {
    width: 100%;
    max-width: 278px;
    height: 168px;
    border-radius: 6px !important;
}

.round-video-24 iframe {
    border-radius: 24px;
}

.edu-feature {
    width: 100%;
    height: 100%;
    height: 200px;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    align-items: center;
}

.industries-logos img {
    object-fit: contain;
}

.c-social-icons__icon .custom-icon {
    height: 57%;
    flex-basis: 100%;
    color: #687385;
}

.c-social-icons__icon .custom-icon:hover {
    color: black;
    fill: black;
}
.o-plain-list .custom-icon {
    color: #687385;
    fill: #687385;
    font-size: 17px;
}
.o-plain-list .custom-icon:hover {
    color: black;
}

.gallabox-badges-gray {
    background-color: #2d3748;
    padding: 40px 0px 80px 0px;
}

.gallabox-badges-gray .small-badges {
    gap: 40px;
    width: 100%;
    max-width: 865px;
}

.submenu-item {
    border-radius: 6px;
}

.wati-testimonial-blk {
    max-width: 100%;
    max-width: 996px;
    background: #ffffff;
    box-shadow: 0px 20px 50px rgba(51, 100, 250, 0.15);
    border-radius: 12px;
    position: relative;
    padding: 40px 103px;
    text-align: center;
}

.wati-comp-cont {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wati-comp-cont .side-box {
    width: 100%;
    flex-flow: column;
    max-width: 311px;
    background-color: #f2f4f7;
    display: flex;
    gap: 1px;
}

.wati-comp-cont .wati-comp-title {
    font-size: 25px;
    min-height: 82px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.wati-comp-cont .side-box div {
    background-color: white;
}

.wati-comp-cont .middle-box {
    width: 100%;
    max-width: 379.86px;
    gap: 1px;
    background-color: #f2f4f7;
    border: 2px solid #d1e9ff;
    box-shadow: 0px 100px 200px rgba(52, 64, 84, 0.18);
    border-radius: 6px;
    flex-flow: column;
    display: flex;
    z-index: 1;
}

.wati-comp-cont .middle-box .wati-comp-title {
    height: 114px;
}

.wati-comp-cont .middle-box div {
    background-color: white;
    font-weight: 500;
}

.wati-comp-cont .middle-box > div:last-child {
    padding-bottom: 52px !important;
}

.comp-bar .bar-cont {
    width: 308px;
    background: #ffffff;
    border: 0.5px solid #53b1fd;
    border-radius: 50px;
    height: 20px;
}

.comp-bar .bar-cont .fill-bar {
    background: #53b1fd;
    border-radius: 50px;
    height: 100%;
}

.comp-bar .bar-cont-gray {
    width: 308px;
    background: #ffffff;
    border: 0.5px solid #d0d5dd;
    border-radius: 50px;
    height: 20px;
}

.comp-bar .bar-cont-gray .fill-bar-gray {
    background: #d0d5dd;
    border-radius: 50px;
    height: 100%;
}

.barscore-box {
    display: grid;
    grid-template-columns: 400px auto;
}

.img45 {
    width: 45% !important;
}

.img40 {
    width: 40% !important;
}

.wati-comp-mobile {
    display: none;
}

.wati-comp-mobile > div {
    background-color: white;
    padding: 9px 7px;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wati-top-green {
    font-size: 16px;
}

.wati-top-green img {
    width: 24px;
}

.wati h1 {
    font-size: 64px;
    line-height: 80px;
}

.wati-content-head {
    font-size: 32px;
    list-style: 37px;
}

.zapier-spl-cont {
    width: 100%;
    max-width: 996px;
    margin: auto;
}

.zapier-spl-img-cont {
    display: flex;
    justify-content: center;
    background-color: #edfcf2;
}

.zapier-spl-img-cont .zapier-img-cont {
    background-color: #edfcf2;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-basis: 50%;
}

.zapier-spl-img-cont .zapier-logo-cont img {
    width: 102px;
    margin-top: 17px;
}

.zapier-spl-img-cont .zapier-img-cont {
    flex-basis: 50%;
}

.zapier-spl-img-cont .zapier-logo-cont {
    display: flex;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #f2f4f7;
    border-radius: 12px;
}

.zapier-spl-cont .button-cont {
    background: #eff8ff;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 16px;
    height: 62px;
}

.zapier-spl-cont .button-cont-mobile {
    display: hidden;
}

.zapier-spl-cont .selected-button {
    background: #ffffff;
    border: 1px solid #f2f4f7;
    border-radius: 12px;
    padding: 20px 73px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: 40ms backbground ease-in-out;
    z-index: 4;
}

.zapier-spl-cont button:focus,
.zapier-spl-cont button:active {
    outline: none !important;
    border: none !important;
}

/* .zapier-spl-cont button{
    padding: 20px 73px;
} */
.pale-blue-bg-mobile {
    background-color: white;
}

.wati-header-img {
    width: 100%;
}

.wati-header-img > div {
    position: unset !important;
}

.wati-header-img .img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
}

.header-img-section {
    flex-basis: 50%;
}

.wati-content-img {
    flex-basis: 50px;
}

.header-img-45 {
}

.slider-and-content-home {
    width: 100%;
    max-width: 1140px;
    margin: auto auto;
}

.slider-and-content-b-home {
    flex-basis: 40%;
}
.slider-and-content-a-home {
    width: 50%;
}

.sequence-spl-cont {
    width: 100%;
    max-width: 1203px;
    margin: auto;
}

.payments-spl-cont {
    width: 100%;
    max-width: 1203px;
    margin: auto;
}

.sequence-spl-img-cont {
    display: flex;
    justify-content: center;
    background-color: #edfcf2;
}

.sequence-spl-img-cont .sequence-img-cont {
    background-color: white;
    display: flex;
    align-items: flex-end;
    flex-basis: 50%;
    object-fit: cover;
}

.sequence-spl-img-cont .sequence-logo-cont img {
    width: 102px;
    margin-top: 17px;
}

.sequence-spl-img-cont .sequence-img-cont {
    flex-basis: 50%;
    object-fit: cover;
}

.sequence-spl-img-cont .sequence-logo-cont {
    display: flex;
    flex-basis: 50%;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #f2f4f7;
    border-radius: 12px;
}

.sequence-spl-cont .button-cont-sequence {
    background: #ebf8ff;
    border-radius: 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 16px;
    width: 1203px;
    height: 80px;
    padding: 0px 8px 0px 8px;
    gap: 25px;
    align-items: center;
}

.payments-spl-cont .button-cont-payments {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-left: 65px;
    padding-right: 0px;
}

.payments-spl-cont .button-cont-ai {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 100px;
    padding-right: 100px;
}

.payments-spl-cont .button-cont-cat {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding-left: 50px;
    padding-right: 50px;
}

.payments-spl-cont .button-cont-payments-inactive {
    background: #d1e9ff;
    color: #33405e;
    border: 1px solid #f2f4f7;
    border-radius: 6px 6px 0 0;
    transition: 40ms;
    height: 42px;
    width: 250px;
    white-space: nowrap;
    outline: none;
    border: none;
}
.payments-spl-cont .button-cont-cat-inactive {
    background: #d1e9ff;
    color: #33405e;
    border: 1px solid #f2f4f7;
    border-radius: 6px 6px 0 0;
    transition: 40ms;
    height: 42px;
    width: fit-content;
    white-space: nowrap;
    outline: none;
    border: none;
}

.payments-spl-cont .button-cont-ai-inactive {
    background: #d1e9ff;
    color: #33405e;
    border: 1px solid #f2f4f7;
    border-radius: 6px 6px 0 0;
    transition: 40ms;
    height: 42px;
    width: 280px;
    white-space: nowrap;
    outline: none;
    border: none;
}
.payments-spl-cont .button-cont-cat-inactive {
    background: #d1e9ff;
    color: #33405e;
    border: 1px solid #f2f4f7;
    border-radius: 6px 6px 0 0;
    transition: 40ms;
    height: 42px;
    width: 200px;
    white-space: nowrap;
    outline: none;
    border: none;
}

.sequence-spl-cont .button-cont-mobile-sequence {
    display: hidden;
}
.payments-spl-cont .selected-button-payments {
    background: #2e90fa;
    color: white;
    border-radius: 6px 6px 0 0;
    border-color: transparent;
    outline: none;
    border: none;
    height: 42px;
    width: 250px;
    white-space: nowrap;
}

.payments-spl-cont .selected-button-ai {
    background: #2e90fa;
    color: white;
    border-radius: 6px 6px 0 0;
    border-color: transparent;
    outline: none;
    border: none;
    height: 42px;
    width: 280px;
    white-space: nowrap;
    border: 1px solid #f2f4f7;

    transition: 40ms;
}
.payments-spl-cont .selected-button-cat {
    background: #2e90fa;
    color: white;
    border-radius: 6px 6px 0 0;
    border-color: transparent;
    outline: none;
    border: none;
    height: 42px;
    width: 200px;
    white-space: nowrap;
    border: 1px solid #f2f4f7;

    transition: 40ms;
}

.sequence-spl-cont .selected-button-sequence {
    background: #ffffff;
    border: 1px solid #f2f4f7;
    border-radius: 6px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: 40ms backbground ease-in-out;
    z-index: 4;
    height: 60px;
}

.sequence-spl-cont button:focus,
.sequence-spl-cont button:active {
    outline: none !important;
    border: none !important;
}

@media (max-width: 450px) {
    .slider-and-content-a-home {
        flex-basis: 100%;
    }

    .slider-and-content-b-home {
        flex-basis: 100%;
    }

    .zapier-spl-cont .button-cont {
        display: none;
    }

    .zapier-spl-cont .button-cont-mobile {
        background: #eff8ff;
        border-radius: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 16px;
    }

    .pale-blue-bg-mobile {
        background-color: #f6fbff;
    }

    .zapier-spl-img-cont .zapier-logo-cont {
        flex-basis: 100%;
    }

    .zapier-spl-img-cont {
        flex-wrap: wrap-reverse;
    }

    .zapier-spl-img-cont .zapier-img-cont {
        flex-basis: 100%;
    }

    .zapier-spl-img-cont .zapier-logo-cont {
        display: flex;
    }

    .button-cont-mobile .drop-down-content {
        position: absolute;
        width: 100%;
        background-color: rgb(247, 247, 247);
        top: 74%;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    }

    .button-cont-mobile .drop-down-content button:hover {
        background-color: gray;
    }

    .button-cont-mobile .drop-down-content button {
        background-color: white;
        margin-top: 1px;
        margin-bottom: 1px;
    }

    .header-img-section {
        flex-basis: 100%;
    }

    .comp-bar .bar-cont-gray {
        width: 308px;
        background: #ffffff;
        border: 0.5px solid #d0d5dd;
        border-radius: 50px;
        height: 15px;
    }

    .comp-bar .bar-cont {
        width: 308px;
        background: #ffffff;
        border: 0.5px solid #53b1fd;
        border-radius: 50px;
        height: 15px;
    }

    .wati-content-head {
        font-size: 24px;
        list-style: 37px;
    }

    .wati-comp-mobile > :nth-child(1) {
        border-top-left-radius: 6px;
    }

    .wati-comp-mobile > :nth-child(3) {
        border-top-right-radius: 6px;
    }

    .wati-comp-mobile > :nth-child(22) {
        border-bottom-left-radius: 6px;
    }

    .wati-comp-mobile > :nth-child(24) {
        border-bottom-right-radius: 6px;
    }

    .wati-comp-mobile .wati-comp-title {
        font-size: 16px !important;
    }

    .wati h1 {
        font-size: 32px;
        line-height: 40px;
    }

    .wati-top-green img {
        width: 15px;
    }

    .wati-top-green {
        font-size: 12px;
        margin-bottom: 5px !important;
    }

    .wati-comp-mobile {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1px;
        box-shadow: 0px 39px 79px 11px #2e90fa21;
        background: #f2f4f7;
        border-radius: 6px;
    }

    .barscore-box {
        grid-template-columns: 400px;
    }

    .img45 {
        width: 100% !important;
    }

    .img40 {
        width: 100% !important;
    }

    .wati-testimonial-blk {
        padding: 30px 10px;
    }

    .submenu-item {
        border-radius: 6px;
        height: 80px;
    }

    .industries-logos img {
        width: 30%;
        object-fit: contain;
    }

    .bfsi .image-content-block .reverse .description {
        max-width: 100% !important;
    }

    .yellow-cta input {
        max-width: 100%;
    }

    .industries-header-head > div {
        width: 100%;
    }

    .propleaf-video-cont {
        width: 100%;
        max-width: 767px;
        height: 80%;
        margin: 25px auto;
    }

    .comparisons-header {
        margin-top: 0px;
        background-color: #ffffff;
        background-image: url(/comparison-page/header-banner.svg);
        background-repeat: repeat;
    }

    .comp-pros-table-grid {
        display: grid;
        grid-template-columns: 270px 335px 335px;
        gap: 2px;
        background-color: #d0e5ff;
        width: 160%;
    }

    .mask {
        top: 64px !important;
        right: -4px !important;
    }

    .home-head-convo {
        margin-left: 0px;
    }

    .into-txt {
        margin-left: -198px;
    }

    .head-text-top-span {
        margin-left: 2%;
        font-family: "Raleway";
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        height: 48px;
        bottom: -2px;
    }

    .mob-pad {
        padding: 0 24px;
    }

    .grid-item-comp {
        height: 90px;
    }

    h2 + .description {
        text-align: center !important;
    }

    .integrations-cms .landing-description,
    .integrations .landing-description {
        text-align: center !important;
    }

    .qr-benefits {
        justify-content: center;
        text-align: center;
        padding: 25px 20px 10px 20px;
    }

    .qr-benefits .qr-icon {
        margin-left: 0px;
    }

    .pricing-table {
        grid-template-columns: 48% 50% 50% 50%;
        overflow: auto;
    }

    /* .pricing-table-outer div{
        z-index: 0;
        overflow: auto;
      } */
    .table-title-sub {
        position: sticky;
        left: 0px;
        padding-left: 10px !important;
    }

    .pricing-table-outer-sticky {
        display: none;
    }

    .no-gap-pricing {
        margin-left: 0px;
        margin-right: 0px;
    }

    .pricing-table-title {
        grid-column: auto;
    }

    .pricing-table > div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pricing-header h1 {
        font-size: 40px !important;
        font-weight: 700 !important;
        line-height: 51px !important;
        letter-spacing: 0.02em !important;
    }

    .mobile-plan-pricing {
        background-color: #fff;
        text-align: center;
        width: 100%;
        max-width: 342px;
        padding: 20px 44px;
        border: 1.02941px solid #e2e8f0;
        border-radius: 6.17647px;
    }

    .mobile-plan-pricing .pricing-plan-icon {
        width: 100%;
        max-width: 310px;
        text-align: left;
        margin: 21px auto;
    }

    .mobile-plan-pricing .try-btn {
        font-weight: 500;
        font-size: 1rem;
        border: 1px solid var(--blue-500);
        color: var(--blue-500);
        border-radius: 6px;
        padding: 0.5em 1em;
    }

    .mobile-plan-pricing .try-btn:hover {
        background-color: var(--blue-500);
        border: 1px solid var(--blue-500);
        color: #fff;
    }

    .mobile-plan-pricing .price-description {
        font-size: 14px;
        font-weight: 400px;
        margin-top: 2px;
    }

    .pricing-header h1 {
        font-size: 24px !important;
        font-weight: 700 !important;
        line-height: 30px !important;
        letter-spacing: 0.02em !important;
    }

    .mobile-plan-pricing {
        background-color: #fff;
        text-align: center;
        width: 100%;
        max-width: 342px;
        padding: 20px 44px;
        border: 1.02941px solid #e2e8f0;
        border-radius: 6.17647px;
    }

    .mobile-plan-pricing .pricing-plan-icon {
        width: 100%;
        max-width: 310px;
        text-align: left;
        margin: 21px auto;
    }

    .mobile-plan-pricing .try-btn {
        font-weight: 500;
        font-size: 1rem;
        border: 1px solid var(--blue-500);
        color: var(--blue-500);
        border-radius: 6px;
        padding: 0.5em 1em;
    }

    .mobile-plan-pricing .try-btn:hover {
        background-color: var(--blue-500);
        border: 1px solid var(--blue-500);
        color: #fff;
    }

    .mobile-plan-pricing .price-description {
        font-size: 14px;
        font-weight: 400px;
        margin-top: 2px;
    }

    /* .pricing-table {
        grid-template-columns: 48% 50% 50%;
        overflow: auto;
    } */

    /* .pricing-table-outer div{
        z-index: 0;
        overflow: auto;
      } */
    .table-title-sub {
        position: sticky;
        left: 0px;
        padding-left: 10px !important;
    }

    .pricing-table-outer-sticky {
        display: none;
    }

    .no-gap-pricing {
        margin-left: 0px;
        margin-right: 0px;
    }

    .pricing-table-title {
        grid-column: auto;
    }

    .pricing-table > div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .pricing-table-title {
        margin-bottom: 0px;
    }

    .two-card-testimonial {
        height: 402px !important;
    }

    .visa-header-banner h1 {
        font-size: 45px;
    }

    .visa-page-form {
        padding: 25px 20px;
    }
    .sequence-spl-cont {
        width: 100%;
        max-width: 1203px;
        margin: auto;
    }

    .sequence-spl-img-cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #edfcf2;
    }

    .sequence-spl-img-cont .sequence-img-cont {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-basis: 50%;
    }

    .sequence-spl-img-cont .sequence-logo-cont img {
        width: 102px;
        margin-top: 17px;
    }

    .sequence-spl-img-cont .sequence-img-cont {
        flex-basis: 100%;
    }

    .sequence-spl-img-cont .sequence-logo-cont {
        display: flex;
        flex-basis: 50%;
        justify-content: center;
        align-items: center;
        background-color: white;
        border: 1px solid #f2f4f7;
        border-radius: 12px;
    }

    .sequence-spl-cont .button-cont-sequence {
        display: none;
    }

    .sequence-spl-cont .button-cont-mobile-sequence {
        display: hidden;
    }

    .sequence-spl-cont .selected-button-sequence {
        background: #ffffff;
        border: 1px solid #f2f4f7;
        border-radius: 12px;
        padding: 20px 73px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        transition: 40ms backbground ease-in-out;
        z-index: 4;
    }

    .sequence-spl-cont button:focus,
    .sequence-spl-cont button:active {
        outline: none !important;
        border: none !important;
    }
}

.hero-section,
.section-metric,
.section-feature,
.section-testimonial,
.section-business-logo-list,
.bot-template-section,
.book-free-trial-section {
    max-width: 1152px;
    overflow: visible;
}
.metric-content {
    width: 560px;
}

.outline-btn {
    width: 200px;
    color: black;
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 8px;
}

@media (min-width: 1020px) {
    .book-free-trial-section {
        height: 172px !important;
    }
}

@media (min-width: 1020px) {
    .section-feature {
        height: 400px !important;
    }
}

@media (min-width: 1020px) {
    .bot-template-section {
        height: 800px !important;
    }
}

@media (min-width: 1020px) {
    .section-metric {
        height: 752px !important;
    }
}

@media (min-width: 1020px) {
    .hero-section {
        height: 720px !important;
    }
}

@media (min-width: 1020px) {
    .section-testimonial {
        height: fit-content !important;
    }
}
@media (min-width: 1020px) {
    .section-business-logo-list {
        height: 308px !important;
    }
}

.hero-section,
.section-metric,
.section-feature,
.section-testimonial,
.section-business-logo-list,
.bot-template-section,
.book-free-trial-section {
    max-width: 1152px;
    overflow: visible;
}
.metric-content {
    width: 560px;
}

.outline-btn {
    width: 200px;
    color: black;
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 8px;
}

@media (min-width: 1020px) {
    .book-free-trial-section {
        height: 172px !important;
    }
}

@media (min-width: 1020px) {
    .section-feature {
        height: 400px !important;
    }
}

@media (min-width: 1020px) {
    .bot-template-section {
        height: 800px !important;
    }
}

@media (min-width: 1020px) {
    .section-metric {
        height: 752px !important;
    }
}

@media (min-width: 1020px) {
    .hero-section {
        height: 720px !important;
    }
}

@media (min-width: 1020px) {
    .section-testimonial {
        height: fit-content !important;
    }
}
@media (min-width: 1020px) {
    .section-business-logo-list {
        height: 308px !important;
    }
}

.hero-section,
.section-metric,
.section-feature,
.section-testimonial,
.section-business-logo-list,
.bot-template-section,
.book-free-trial-section {
    max-width: 1152px;
    overflow: visible;
}
.metric-content {
    width: 560px;
}

.outline-btn {
    width: 200px;
    color: black;
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 8px;
}

@media (min-width: 1020px) {
    .book-free-trial-section {
        height: 172px !important;
    }
}

@media (min-width: 1020px) {
    .section-feature {
        height: 400px !important;
    }
}

@media (min-width: 1020px) {
    .bot-template-section {
        height: 800px !important;
    }
}

@media (min-width: 1020px) {
    .section-metric {
        height: 752px !important;
    }
}

@media (min-width: 1020px) {
    .hero-section {
        height: 720px !important;
    }
}

@media (min-width: 1020px) {
    .section-testimonial {
        height: fit-content !important;
    }
}
@media (min-width: 1020px) {
    .section-business-logo-list {
        height: 308px !important;
    }
}

.hero-section,
.section-metric,
.section-feature,
.section-testimonial,
.section-business-logo-list,
.bot-template-section,
.book-free-trial-section {
    max-width: 1152px;
    overflow: visible;
}
.metric-content {
    width: 560px;
}

.outline-btn {
    width: 200px;
    color: black;
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 8px;
}

@media (min-width: 1020px) {
    .book-free-trial-section {
        height: 172px !important;
    }
}

@media (min-width: 1020px) {
    .section-feature {
        height: 450px !important;
    }
}

@media (min-width: 1020px) {
    .bot-template-section {
        height: 800px !important;
    }
}

@media (min-width: 1020px) {
    .section-metric {
        height: 752px !important;
    }
}

@media (min-width: 1020px) {
    .hero-section {
        height: 800px !important;
    }
}

@media (min-width: 1020px) {
    .section-testimonial {
        height: fit-content !important;
    }
}
@media (min-width: 1020px) {
    .section-business-logo-list {
        height: 308px !important;
    }
}
@media (min-width: 1020px) {
    .feature-card_section {
        height: 500px !important;
    }
}

.carousal-dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
}
.carousal-dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
}
.carousal-dot:focus {
    outline: none;
}

.carousal-dot.active {
    background: #1d4ed8;
}

/* @media (min-width:650px) {
    .propleaf-video-cont{
        width: 100%;
        max-width: 767px;
        height: 431px;
    }
} */

.sidevector::after {
    position: absolute;
    width: 0;
    height: 0;
    left: -12px;
    right: auto;
    top: 0;
    bottom: auto;
    border-width: 13px;
    border-top-color: white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    content: "";
}
a.banner_button {
    background-color: #25d366;
}
a.banner_button:hover {
    background-color: #25d365d8;
}
.rating-gallabox-range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    border: 0.5px solid #53b1fd;
}
.rating-gallabox-range::-webkit-slider-runnable-track {
    height: 15px;
    background: #fff;
    border-radius: 16px;
}
.rating-gallabox-range::-moz-range-track {
    height: 15px;
    background: #fff;
    border-radius: 16px;
}
.rating-gallabox-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #2e90fa;
    border-radius: 50%;
    color: #2e90fa;
    box-shadow: -207px 0 0 200px #2e90fa;
}
.rating-gallabox-range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    height: 22px;
    width: 22px;
    background-color: #2e90fa;
    border-radius: 50%;
    color: #2e90fa;
    outline: none;
    border: none;
    box-shadow: -207px 0 0 200px #2e90fa;
}
.rating-competitor-range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    border: 0.5px solid #d0d5dd;
}
.rating-competitor-range::-webkit-slider-runnable-track {
    height: 15px;
    background: #fff;
    border-radius: 16px;
}
.rating-competitor-range::-moz-range-track {
    height: 15px;
    background: #fff;
    border-radius: 16px;
}
.rating-competitor-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    width: 15px;
    background-color: #d0d5dd;
    border-radius: 50%;
    color: #d0d5dd;
    box-shadow: -207px 0 0 200px #d0d5dd;
}
.rating-competitor-range::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    height: 22px;
    width: 22px;
    background-color: #d0d5dd;
    border-radius: 50%;
    color: #d0d5dd;
    outline: none;
    box-shadow: -207px 0 0 200px #d0d5dd;
    border: none;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #4299e1;
}

input:focus + .slider {
    box-shadow: 0 0 1px #4299e1;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.truncate-heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    max-width: 90%;
    max-height: 90%;
    overflow: auto; /* Allow scrolling if content exceeds the viewport */
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-container {
    position: relative;
    width: 800px;
    height: 400px;
}

.video-container > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.exit-button {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    width: 400px; /* Fixed width */
    height: 200px; /* Fixed height */
    background-color: red; /* Example background color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

@media (max-width: 768px) {
    .video-container {
        position: relative;
        width: 350px;
        height: 200px;
    }
}

.faq-section .accordion-item {
    border-top: 1px solid #2d3748;
}

.faq-section .accordion-header {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 0;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.faq-section .rotated {
    transform: rotate(45deg);
}

.faq-section .accordion-content {
    font-size: 16px;
    line-height: 1.6;
    display: none;
}

.faq-section .accordion-item.active .accordion-content {
    display: block;
    padding-bottom: 28px;
    cursor: pointer;
}

.faq-section .show-more {
    margin-top: 30px;
    color: #3364fa;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

@media (min-width: 700px) {
    .CookieConsent {
        background: white !important;
        border: 1px solid #cbd5e0 !important;
        bottom: 20px !important;
        width: 780px !important;
        right: unset !important;
        left: 20px !important;
        display: block !important;
        border-radius: 8px !important;
        position: absolute !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 20px 0px !important;
    }
    .CookieConsent > div {
        margin: 10px !important;
    }
}
@media (max-width: 600px) {
    .CookieConsent {
        background: white !important;
        display: block !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 20px 0px !important;
    }
}

#rcc-confirm-button {
    display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
    flex-direction: row-reverse !important;
}

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

@media (max-width: 768px) {
    .mobile-view {
        height: 1500px;
    }
}
